<template>
  <div>
    <l-draggable-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'inventory-asset-condition-taxonomies' }"
      trash-list-path="inventory-asset-condition-trash-list"
      :is-pagination-able="false"
      :is-searchable="false"
      @onChoose="onChoose"
    >
      <template
        #cell(actions)="{ data }"
      >
        <div
          class="d-flex justify-content-end"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LTrashIconKits"
            :class="data.default ? 'd-none' : 'cursor-pointer mr-1'"
            size="32"
            :title="$t('Set Inactive')"
            @click="setInActive(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LCopyIconKits"
            size="32"
            class="cursor-pointer mr-1"
            :title="$t('Copy')"
            @click="duplicate(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('View')"
            icon="LStockEyeIcon"
            class="cursor-pointer"
            size="32"
            @click="gotoNewPage({
              name: 'inventory-asset-condition-update',
              params: { id: data.id },
            }, $event)"
          />
        </div>
      </template>
      <template #cell(active)="data">
        {{ data.data.active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(default)="{ data }">
        <div
          v-if="data.active"
          class="ml-2"
        >
          <span
            :id="data.id"
            class="cursor-pointer d-flex align-items-center position-relative"
            style="width: max-content; height: 20px; opacity: 0.9 "
            :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
            @click.once="setDefault(data)"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="ml-2 custom-control-group"
            >
              <b-form-radio
                v-model="data.default"
                class="mt-1"
                :aria-describedby="ariaDescribedby"
                :name="`some-radios-${data.id}`"
                :value="true"
                size="lg"
              />
            </b-form-group>
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px; left: 15px; top: 4px"
              class="ml-1 position-absolute"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </span>
        </div>
        <div v-else />
      </template>
    </l-draggable-table>
  </div>
</template>

<script>
import {
  VBTooltip, BFormGroup, BFormRadio, BSpinner,
} from 'bootstrap-vue'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import config from '../assetConditionConfig'

export default {
  name: 'AssetConditionList',
  components: {
    LDraggableTable,
    BFormGroup,
    BFormRadio,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
      draggedItem: null,
    }
  },
  computed: {
    inventoryList() {
      return this.$store.state[this.MODULE_NAME].inventoryList
    },
  },
  created() {
    this.$emit('updateMenu', 'inventory-asset-condition-list')
  },
  methods: {
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    refetchData() {
      this.$refs.lTableRef.fetchTableItemsList()
    },
    setInActive(data) {
      const { id } = data
      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
    setDefault(data) {
      const { id } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    onChoose(item) {
      const { moved: { element: { id }, newIndex } } = item

      this.sendNotification(
        this, { id: Number(id), position: Number(newIndex) },
        `${this.MODULE_NAME}/setPosition`,
      ).then(() => {
        this.refetchData()
        this.isLoadingOfSetDisabled = false
      })
    },
    duplicate(data) {
      this.$refs.lTableRef.isBusy = true
      this.$store.dispatch(`${this.MODULE_NAME}/duplicate`, data).then(() => {
        this.refetchData()
      }).catch(() => {
        this.$refs.lTableRef.isBusy = false
      })
    },
  },
  setup() {
    const MODULE_NAME = 'asset-condition-taxonomies'
    const { tableColumns, fields, autoSuggestTableColumns } = config()

    return {
      MODULE_NAME,
      fields,
      tableColumns,
      autoSuggestTableColumns,
    }
  },
}
</script>

<style scoped>
</style>
